import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Andrew Weatherman</h2>
        <p><a href="mailto:andrew@aweatherman.com">andrew@aweatherman.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I'm Andrew. I am a <a href="https://duke.edu/">Duke University</a> graduate, a data enthusiast, and a massive R nerd. 
      Previously, I was a men's basketball student manager at <a href="https://www.nytimes.com/2018/03/16/sports/duke-basketball-managers.html">Duke </a>
      and the Executive President of <a href="https://www.deca.org/">DECA Inc</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Andrew Weatherman <Link to="/">aweatherman.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
