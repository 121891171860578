const routes = [
  {
    index: true,
    label: "Andrew Weatherman",
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: 'https://drive.google.com/file/d/11Jl8WAsHAxIXiF8Tiyur9MeINBueL3CM/view?usp=sharing',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Visualizations',
    path: '/viz',
  },
  {
    label: 'Blog',
    path: 'https://bucketsandbytes.com',
  },
  {
    label: 'Spotify',
    path: 'https://music.aweatherman.com',
  },
  {
    label: 'Stats',
    path: '/stats',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
